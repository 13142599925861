export const MaxProfileNameLength = 50;
export const MaxProfileStoryLength = 1000;
export const MaxProfileImageSize = 1;//MB
export const MaxProfileImageWidth = 400;
export const MaxProfileSNSLength = 50;
export const MaxProfileWebSiteLength = 200;

export const MinProfilePublicKeyLength = 5;
export const MaxProfilePublicKeyLength = 20;

export const MaxPaymentNameLength = 100;
export const MaxPaymentAddrLength = 100;
export const MaxPaymentZipLength = 8;
export const MaxPaymentTelLength = 13;
export const MaxPaymentMailLength = 100;

export const MaxPaymentBankLength = 100;
export const MaxPaymentBranchLength = 200;
export const MaxPaymentAccountNumberLength = 7;
export const MaxPaymentAccountHolderLength = 200;

export const MaxProductNameLength = 50;
export const MaxProductUrlLength = 150;
export const MinProductPrice = 100;
export const MaxProductPrice = 20000;
export const MaxProductIntroductionLength = 2000;
export const MaxProductAboutServiceFileLength = 500;
export const MaxProductAboutSampleFileLength = 500;
export const MaxProductFileNameLength = 100;
export const MaxProductFileSize = 1024*1024*200;//1ファイルのサイズ上限　200MB
export const MaxProductFiles = 5;//商品1つにアップロードできるファイルの個数
export const CompressedProductFileSize = 1024*1024*10;//圧縮対象になるファイルサイズ　10MB以上
export const MaxProductServiceFiles = 1;//商品1つにアップロードできる追加ファイルの個数
export const MaxProductSampleFileSize = 1024*1024*50;//1サンプルファイルのサイズ上限　200MB
export const MaxProductSampleFiles = 1;//商品1つにアップロードできるサンプルファイルの個数
export const MaxProductImageSize = 3;//MB
export const MaxProductImageWidth = 2048;
export const MaxProductImageMax = 6;//イメージ画像の枚数
export const MaxProductThumbnailSize = 1;//MB
export const MaxProductThumbnailWidth = 600;
export const MaxProducts = 10;


export const PaymentDeposits:{[key:string]:string;}  = {
	"1": "普通",
	"2": "当座",
	"3": "貯蓄",
};

export const PaymentMinAmounts:{[key:number]:string;}  = {
	"1000": "1,000円",
	"2000": "2,000円",
	"3000": "3,000円",
	"5000": "5,000円",
	"10000": "10,000円",
	"20000": "20,000円",
};

//アップロードできるファイルと、アップロード時の圧縮の有無（結局テキスト以外圧縮しない）
export const AvailableFileTypes:{[key:string]:boolean;} = {
	"png": false,
	"jpg": false,
	"gif": false,
	"txt": true,
	"docx": false,
	"epub": false,
	"xlsx": false,
	"pdf": false,
	"ai":false,
	"psd":false,
	"mpg": false,
	"mpeg": false,
	"mp4": false,
	"mp3": false,
	"wav": false,
	"aiff": false,
	"aac": false,
	"flac": false,
	"ttf": false,
	"otf": false,
}

export interface Dataset {
	id: string,
	label: string,	
}

export interface Profile {
	id: number,	
	name: string,
	story: string,
	publicKey: string,
	imageKey: string,
	facebook: string,
	twitter: string,
	instagram: string,
	youtube: string,
	webSite: string,
	example: boolean,
	locked: boolean,
	reason: string,
	available: boolean,
	createdAt: string,
	updatedAt: string,
}

export interface ProfileError {
	id: string,
	name: string,
	story: string,
	publicKey: string,
	imageKey: string,
	facebook: string,
	twitter: string,
	instagram: string,
	youtube: string,
	webSite: string,
	locked: string,
	reason: string,
	available: string,
	createdAt: string,
	updatedAt: string,
}

export interface Payment {
	id: number,
	name: string,
	zip: string,
	addr: string,
	tel: string,
	email: string,
	bank: string,
	branch: string,
	deposit: string,//1:普通 2:当座 3:貯蓄
	accountNumber: string,
	accountHolder: string,
	minAmount: number,
	locked: boolean,
	reason: string,
	checked: boolean,
	available: boolean,
	createdAt: string,
	updatedAt: string,
}

export interface PaymentError {
	id: string,
	name: string,
	zip: string,
	addr: string,
	tel: string,
	email: string,
	bank: string,
	branch: string,
	deposit: string,
	accountNumber: string,
	accountHolder: string,
	minAmount: string,
	locked: string,
	reason: string,
	checked: string,
	available: string,
	createdAt: string,
	updatedAt: string,
}

export interface Product {
	id: string,
	Userid: string,
	name: string,
	imageKeys: string,
	thumbnailKey: string,
	fileIds: string,
	fileNames: string,
	fileSizes: string,
	serviceFileIds: string,
	serviceFileNames: string,
	serviceFileSizes: string,
	sampleFileIds: string,
	sampleFileNames: string,
	sampleFileSizes: string,
	introduction: string,
	aboutServiceFile: string,
	aboutSampleFile: string,
	price: number,
	url: string,
	start: string,
	lastSale: string,
	sales: number,
	locked: boolean,
	reason: string,
	available: boolean,
	createdAt: string,
	updatedAt: string,
}
export interface ProductError {
	id: string,
	Userid: string,
	name: string,
	imageKeys: string,
	thumbnailKey: string,
	fileIds: string,
	fileNames: string,
	serviceFileIds: string,
	serviceFileNames: string,
	sampleFileIds: string,
	sampleFileNames: string,
	introduction: string,
	aboutServiceFile: string,
	aboutSampleFile: string,
	price: string,
	url: string,
	start: string,
	lastSale: string,
	sales: string,
	locked: string,
	reason: string,
	available: string,
	createdAt: string,
	updatedAt: string,
}

export interface Transfer {
	id: number,
	uniqueKey: string,
	profile: Profile,
	payment: Payment,
	transferDate: string,
	chargeAmount: number,
	refundAmount:number,
	depositAmount:number,
	charges:number,
	refunds:number,
	fee:number,
	name:string,
	zip:string,
	addr:string,
	tel:string,
	email:string,
	bank:string,
	branch:string,
	deposit:string,
	accountNumber:string,
	accountHolder:string,
	status: string,
	locked: string,
	reason: string,
	available: string,
	createdAt: string,
	updatedAt: string,
}

export interface ImageData {
	name: string,
	source: string,
	size: number,
	result: boolean,
}

export interface ProfileAdmin {
	id: number,	
	userId: string,
	name: string,
	story: string,
	publicKey: string,
	imageKey: string,
	facebook: string,
	twitter: string,
	instagram: string,
	youtube: string,
	webSite: string,
	example: boolean,
	paymentName:string,
	zip:string,
	addr:string,
	tel:string,
	email:string,
	bank:string,
	branch:string,
	deposit:string,
	accountNumber:string,
	accountHolder:string,
	minAmount:number,
	checked: boolean,
	locked: boolean,
	reason: string,
	available: boolean,
	createdAt: string,
	updatedAt: string,
}

export interface ProfileAdminSearch {
	name: string,
	size: number,
	page: number,
	order: string,
	asc: string,
}

export interface ProductAdmin {
	id: string,
	userId: string,
	name: string,
	profileName: string,
	imageKeys: string,
	thumbnailKey: string,
	fileIds: string,
	fileNames: string,
	fileSizes: string,
	serviceFileIds: string,
	serviceFileNames: string,
	serviceFileSizes: string,
	sampleFileIds: string,
	sampleFileNames: string,
	sampleFileSizes: string,
	introduction: string,
	aboutServiceFile: string,
	aboutSampleFile: string,
	price: number,
	url: string,
	start: string,
	lastSale: string,
	sales: number,
	locked: boolean,
	reason: string,
	available: boolean,
	createdAt: string,
	updatedAt: string,
}

export interface ProductAdminSearch {
	name: string,
	userId: string,
	size: number,
	page: number,
	order: string,
	asc: string,
}

export interface OrderAdmin {
	id: number,
	orderId: number,
	productName: string,
	profileName: string,
	processedAt: string,
	productId: string,
	profileId: number,
	paymentMethod: string,
	amount: number,
	orderNumber: string,
	email: string,
	locked: boolean,
	reason: string,
	downloaded: boolean,
	canceled: boolean,
	paidFee: number,
	usageFee: number,
	paidFeePer: number,
	usageFeePer: number,
	transferMonth: string,
	refundMonth: string,
	status: string,
	createdAt: string,
	updatedAt: string,
}

export interface OrderAdminSearch {
	productName: string,
	profileName: string,
	orderNumber: string,
	userId: string,
	status: string,
	size: number,
	page: number,
	order: string,
	asc: string,
}

export interface TransferAdmin {
	id: number,
	uniqueKey: string,
	profileName: string,
	paymentName: string,
	userId: string,
	transferDate: string,
	chargeAmount: number,
	refundAmount:number,
	depositAmount:number,
	charges:number,
	refunds:number,
	fee:number,
	name:string,
	zip:string,
	addr:string,
	tel:string,
	email:string,
	method:string,
	bank:string,
	branch:string,
	deposit:string,
	transferFee:number,
	accountNumber:string,
	accountHolder:string,
	status: string,
	locked: string,
	reason: string,
	available: string,
	createdAt: string,
	updatedAt: string,
}

export interface TransferAdminSearch {
	profileName: string,
	paymentName: string,
	transferDate: string,
	userId: string,
	size: number,
	page: number,
	order: string,
	asc: string,
}

export interface InquiryAdmin {
	id: number,
	name: string,
	email: string,
	body: string,
	reply: string,
	sendAt: string,
	appId: number,
	noReply: boolean,
	status: string,
	createdAt: string,
	updatedAt: string,
}

export interface InquiryAdminSearch {
	name: string,
	email: string,
	body: string,
	status: string,
	size: number,
	page: number,
	order: string,
	asc: string,
}

export class Inc {
	static readonly defaultProfile = ()=>{
		let profile:Profile = {
			id: 0,
			name: "",
			story: "",
			publicKey: "",
			imageKey: "",
			facebook: "",
			twitter: "",
			instagram: "",
			youtube: "",
			webSite: "",
			example: false,
			locked: false,
			reason: "",
			available: false,
			createdAt: "",
			updatedAt: "",
		}
		return profile;
	}

	static readonly defaultProfileError = ()=>{
		let error:ProfileError = {
			id: "",
			name: "",
			story: "",
			publicKey: "",
			imageKey: "",
			facebook: "",
			twitter: "",
			instagram: "",
			youtube: "",
			webSite: "",
			locked: "",
			reason: "",
			available: "",
			createdAt: "",
			updatedAt: "",
		}
		return error;
	}

	static readonly defaultPayment = ()=>{
		let payment:Payment = {
			id: 0,
			name: "",
			zip: "",
			addr: "",
			tel: "",
			email: "",
			bank: "",
			branch: "",
			deposit: "1",
			accountNumber: "",
			accountHolder: "",
			minAmount: 1000,
			locked: false,
			reason: "",
			checked: false,
			available: false,
			createdAt: "",
			updatedAt: "",
		}
		return payment;
	}

	static readonly defaultPaymentError = ()=>{
		let error:PaymentError = {
			id: "",
			name: "",
			zip: "",
			addr: "",
			tel: "",
			email: "",
			bank: "",
			branch: "",
			deposit: "",
			accountNumber: "",
			accountHolder: "",
			minAmount: "",
			locked: "",
			reason: "",
			checked: "",
			available: "",
			createdAt: "",
			updatedAt: "",
		}
		return error;
	}

	static readonly defaultProduct = ()=>{
		let product:Product = {
			id: "",
			Userid: "",
			name: "",
			imageKeys: "",
			thumbnailKey: "",
			fileIds: "",
			fileNames: "",
			fileSizes: "",
			serviceFileIds: "",
			serviceFileNames: "",
			serviceFileSizes: "",
			sampleFileIds: "",
			sampleFileNames: "",
			sampleFileSizes: "",
			introduction: "",
			aboutServiceFile: "",
			aboutSampleFile: "",
			price: 100,
			url: "",
			start: "",
			lastSale: "",
			sales: 0,
			locked: false,
			reason: "",
			available: false,
			createdAt: "",
			updatedAt: "",
		}
		return product;
	}

	static readonly defaultProductError = ()=>{
		let error:ProductError = {
			id: "",
			Userid: "",
			name: "",
			imageKeys: "",
			thumbnailKey: "",
			fileIds: "",
			fileNames: "",
			serviceFileIds: "",
			serviceFileNames: "",
			sampleFileIds: "",
			sampleFileNames: "",
			introduction: "",
			aboutServiceFile: "",
			aboutSampleFile: "",
			price: "",
			url: "",
			start: "",
			lastSale: "",
			sales: "",
			locked: "",
			reason: "",
			available: "",
			createdAt: "",
			updatedAt: "",
		}
		return error;
	}

	static readonly getUploadImageURL = (imageKey:String)=>{
		if( imageKey!=="" ){
			return process.env.REACT_APP_IMAGE_VIEW_URL+"img/"+imageKey+".jpg";
		}
		return "";
	}

	static readonly getProductImages = (imageKeys:String)=>{
		let images:ImageData[] = [];
		if( imageKeys ){
			const keys = imageKeys.split("\t");
			for( let i=0; i<keys.length; i++ ){
				if( keys[i]!=="" ){
					images.push({
						name: keys[i],
						source: "",
						size: 0,
						result: true,
					});
				}
			}
		}
		return images;
	}

	static readonly getFileSizeTotal = (fileSizes:string)=>{
		let sum = 0;
		const sizes = fileSizes.split("\t");
		for( let i=0; i<sizes.length; i++ ){
			sum = sum + parseInt(sizes[i]);
		}
		return sum;
	}
}